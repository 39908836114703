<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 scrollPersonalizado">
        <barra-crud :botaoNovo="false" :onFiltrar="filtrar"></barra-crud>

        <div class="row">
          <div class="col-12 col-md-3 p-0 pr-md-0 p-md-1">
            <b-input type="date" v-model="data" class="float-right" block />
          </div>
          <div class="col-12 col-sm-6 p-0   pr-0 pl-0 p-md-1" v-if="hoje">
            <div class="">
              <b-input
                v-model="msg"
                placeholder="digite uma notificação global..."
                @keypress.native="
                  e => {
                    if (e.key == 'Enter') {
                      sendGlobalMessage();
                    }
                  }
                "
              />
            </div>
          </div>
          <div class="col-12 col-sm-3 p-0  pl-0  p-md-1" v-if="hoje">
            <b-button variant="primary" block @click="sendGlobalMessage()"
              ><i class="fa fa-bullhorn"></i> Enviar
            </b-button>
          </div>
        </div>
        <div class=" cardContainer">
          <div
            class="cardNotification"
            :class="{ bordaUnread: noti.lido == 0 }"
            v-for="noti in notificacoesShow"
            :key="noti.id"
            @click="
              e => {
                openNotificacao(noti, e);
              }
            "
          >
            <i :class="'fa fa-' + (noti.tp == 1 ? 'bell' : 'comment')"></i>
            <h6 style="padding-left: 40px;">
              #{{ noti.id }}
              <small class="float-right text-right" style="font-size:12px"
                >{{ noti.data | moment("HH:mm:ss") }}
                <br />
                <b>{{ noti.usuario }}</b>
              </small>
              <br />
              <small class="font-weight-bold">{{ noti.emp }}</small>
            </h6>
            <div class="divider">
              <div>
                <div class="pt-3 pb-3 font-weight-bold text-center">
                  <h6 class="font-weight-bold" v-html="noti.titulo"></h6>
                  <div v-if="noti.observacoes">{{ noti.observacoes }}</div>
                </div>
                <div class="alt">
                  <div
                    v-if="noti.tp == 1 && noti.detalhe && noti.detalhe != null"
                  >
                    <div v-for="ob in Object.keys(noti.detalhe)" :key="ob">
                      <div
                        v-for="ob2 in Object.keys(noti.detalhe[ob])"
                        :key="ob2"
                      >
                        <b>
                          {{ ob2 }}
                        </b>
                        :
                        {{ noti.detalhe[ob][ob2].old }}
                        <span class="iOfLOG">
                          <o class="fa fa-caret-right "></o>
                        </span>
                        {{ noti.detalhe[ob][ob2].new }}
                      </div>
                    </div>
                    <!-- <table
                      class="table tbb"
                      style="font-size:11px"
                      v-for="ob in Object.keys(noti.detalhe)"
                      :key="ob"
                    >
                      <tbody
                        v-for="ob2 in Object.keys(noti.detalhe[ob])"
                        :key="ob2"
                      >
                        <tr>
                          <td class="bgGray" colspan="3">{{ ob2 }}</td>
                        </tr>
                        <tr>
                          <td class=" ">{{ noti.detalhe[ob][ob2].old }}</td>
                          <td class="font-weight-bold iOfLOG">
                            <i class="fa fa-caret-right"></i>
                          </td>
                          <td class=" ">{{ noti.detalhe[ob][ob2].new }}</td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
              </div>
              <div class="coments">
                <div class="text-center font-weight-bold">
                  <b><i class="far fa-comments"></i> Comentários</b>
                  <div class="coments-content scrollPersonalizado">
                    <div
                      style="padding:10px; font-size:10px"
                      v-if="!noti.mensagens || noti.mensagens.length <= 0"
                    >
                      Nenhum comentário ainda :(
                    </div>
                    <div
                      class="msg"
                      v-for="msg in noti.mensagens"
                      :key="`_msg_` + noti.mensagens.indexOf(msg)"
                    >
                      <div class="tit">
                        <i class="far fa-comment"></i> {{ msg.usu }}
                        <span>{{ msg.data | moment("HH:mm:ss") }}</span>
                      </div>
                      <div class="msg-msg">{{ msg.mensagem | msg }}</div>
                    </div>
                  </div>
                  <div class="sendComnet">
                    <b-input
                      placeholder="digite seu comentário"
                      autocomplete="off"
                      v-model="noti.msg"
                      @keypress.native="
                        e => {
                          if (e.key == 'Enter') {
                            sendMessage(noti, noti.msg, true);
                          }
                        }
                      "
                    />
                    <b-button
                      variant="secondary"
                      @click="sendMessage(noti, noti.msg)"
                      ><i class="fa fa-paper-plane"></i
                    ></b-button>
                    <b-button
                      variant="light"
                      @click="sendMessage(noti, ':like:')"
                      ><i class="far fa-thumbs-up text-success"></i
                    ></b-button>
                    <b-button
                      variant="light"
                      @click="sendMessage(noti, ':deslike:')"
                      ><i class="far fa-thumbs-down text-danger"></i
                    ></b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarraCrud from "../components/common/BarraCrud.vue";
// import NotificacoesLib from "../libs/Notificacoes";
import moment from "moment";
// import Chat from "../components/Chat.vue";

export default {
  name: "NotificacoesCOmp",
  sockets: {
    connect() {
      // console.log('conectou sock')
      this.$socket.emit("room", this.codToSock());
      this.carregar();
    },
    solicitarListaNotificacoes() {
      // console.log("solicitarlistanotificacoes");
      this.carregar();
    },
    notificaoLista(data) {
      // console.log("notis", data);
      data.map(noti => {
        if (
          noti.detalhe &&
          noti.detalhe != null &&
          typeof noti.detalhe != "undefined" &&
          noti.tp == 1 &&
          typeof noti.detalhe == "string"
        ) {
          noti.detalhe = JSON.parse(noti.detalhe);
        }
        let fnd = this.notificacoes.find(
          x => x.id == noti.id && x.tipo == noti.tipo
        );
        if (!fnd) {
          this.notificacoes.push(noti);
        } else {
          fnd.mensagens = noti.mensagens;
        }
      });

      if (this.notiOpen) {
        this.notiOpen = this.notificacoes.find(x => x.id == this.notiOpen.id);
      }

      this.filtrar("");

      setTimeout(() => {
        document.getElementsByClassName("coments-content").forEach(it => {
          // console.log("it", it);
          it.scrollTop = 9999999;
        });
      }, 400);
    }
  },
  components: { BarraCrud },

  data() {
    return {
      notificacoes: [],
      notificacoesShow: [],
      user: this.$store.state.auth.user,
      msg: "",
      data: moment().format("YYYY-MM-DD"),
      notiOpen: null,
      empresa: this.$store.state.empresa_ativa
    };
  },
  mounted() {
    this.carregar();
    // console.log('sock',this.$socket)
  },
  watch: {
    "$store.state.notificacoes": function() {
      this.carregar();
    },
    data: function() {
      this.carregar();
    },
    "$store.state.caixa_ativo": function() {
      // console.log("caixa ativo", this.$store.state.caixa_ativo);
      this.$socket.emit("room", this.codToSock());
      this.$forceUpdate();
    }
  },
  computed: {
    hoje() {
      return this.data == moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    codToSock() {
      // let cod = this.$store.state.empresa_ativa.cod_empresa;
      // if (this.$store.state.caixa_ativo.cod_caixa == 4) {
      //   cod = "0";
      // }
      return "USER_" + this.$store.state.auth.user.cod_usuario;
    },
    sendGlobalMessage() {
      this.$socket.emit("room", this.codToSock());

      let user = this.$store.state.auth.user;
      user.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;

      if (this.msg.trim() == "") {
        this.$swal.fire({
          title: "Informe uma mensagem!",
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 2000,
          showConfirmButton: false
        });
      } else {
        this.$socket.emit("sendNotificacao", {
          usuario: user,
          msg: this.msg,
          cod_empresa: this.empresa.cod_empresa
        });
        this.$swal.fire({
          title: "Notificação enviada!",
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 2000,
          showConfirmButton: false
        });
        this.msg = "";
      }
    },
    sendMessage(noti, mensagem, isMessage = false) {
      this.$socket.emit("room", this.codToSock());
      let user = this.$store.state.auth.user;
      user.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;
      if (!mensagem || mensagem.toString().trim() == "") {
        this.$swal.fire({
          title: "Informe uma mensagem!",
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 2000,
          showConfirmButton: false
        });
      } else {
        let obj = {
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
          cod_usuario: this.user.cod_usuario,
          usuario: user,
          mensagem,
          cod_notificacao: noti.id,
          tipo: noti.tp,
          cod_empresa: this.empresa.cod_empresa
        };
        this.$socket.emit("addComentario", obj);
        this.$swal.fire({
          title: "Comentário adicionado!",
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 2000,
          showConfirmButton: false
        });
        if (isMessage) {
          noti.msg = "";
        }
      }
    },

    filtrar(txt) {
      this.$nextTick(() => {
        this.notificacoesShow = this.notificacoes.sort((a, b) => {
          return moment(a.data).isBefore(b.data) ? 1 : -1;
        });
        if (txt && txt != "") {
          txt = txt.toLowerCase();
          this.notificacoesShow = this.notificacoesShow.filter(
            x =>
              (x.titulo && x.titulo.toLowerCase().indexOf(txt) >= 0) ||
              (x.detalhe && JSON.stringify(x.detalhe).indexOf(txt) >= 0) ||
              (x.usuario && x.usuario.indexOf(txt) >= 0) ||
              (x.id && x.id.toString().indexOf(txt) >= 0) ||
              (x.mensagens &&
                x.mensagens.filter(
                  m => m.mensagem.toLowerCase().indexOf(txt) >= 0
                ).length > 0)
          );
        }
      });
    },

    carregar() {
      // console.log("join room", this.codToSock());
      this.$socket.emit("room", this.codToSock());

      let user = this.$store.state.auth.user;
      user.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;

      this.$socket.emit("notificacoesLista", {
        usuario: user,
        data: this.data
      });
      this.$socket.emit("notificacoesMarcarVisu", { usuario: user });

      // this.notificacoes = this.$store.state.notificacoes.map(it => {
      //   if (
      //     it.detalhe &&
      //     it.detalhe != null &&
      //     typeof it.detalhe != "undefined" &&
      //     it.tp == 1 &&
      //     typeof it.detalhe == "string"
      //   ) {
      //     console.log(it.detalhe);
      //     it.detalhe = JSON.parse(it.detalhe);
      //     console.log(it.detalhe);
      //   }
      //   return it;
      // });

      // this.marcarVisualizado(
      //   this.notificacoes.map(it => {
      //     return it.id;
      //   })
      // );
    }
  }
};
</script>
<style lang="scss" scoped>
.scoller {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.scollerChat {
  overflow: none;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.bg-gray {
  background-color: #f9f9f9;
}
.divider {
  div {
    flex-grow: 0;
    flex: 100%;
  }
  .coments {
    display: flex;
    padding-left: 5px;
    i {
      position: relative !important;
      font-size: 1em;
      top: auto;
      left: auto;
      opacity: 1;
    }
    .msg {
      flex: 50%;
      font-size: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      padding: 7px;
      background-color: rgba(255, 255, 255, 0.3);

      flex-grow: 0;
      margin-bottom: 3px;

      div {
        flex-grow: 1;
        width: 100%;
        flex: 100%;
      }
      .msg-msg {
        width: auto;
        padding-left: 19px;
        padding-top: 3px;
        text-align: left;
        font-size: 90%;
      }
      .tit {
        font-weight: bold;
        color: #666;
        padding-left: 5px;

        span {
          // display: block;
          padding-right: 5px;
          font-size: 80%;
        }
      }
    }
    .coments-content {
      width: 100%;
      min-height: 30px;
      height: auto;
      max-height: 150px;
      overflow: hidden;
      overflow-y: auto;
      transition: 0.1s all;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .sendComnet {
      transition: 0.1s all;
      opacity: 0;
      font-size: 11px !important;
      display: flex;
      input {
        font-size: 11px !important;
      }
      button {
        font-size: 11px !important;
      }
      input:focus {
        .sendComnet {
          opacity: 1 !important;
        }
      }
    }
  }
}

.tbb {
  td {
    padding: 1px;
    border-top: none;
  }
  th {
    padding: 1px;
    border-top: none;
  }
}
.msg {
  padding: 10px;
  display: flex;

  input {
    flex-grow: 1;
    flex: 100%;
    // max-width: 50%;
  }
}
.cardContainer {
  display: flex;
  padding: 13px;
  flex-wrap: wrap;
  flex: 2;
}
@media only screen and (max-width: 575px) {
  .cardContainer {
    padding: 0px;
  }
}

.cardNotification {
  flex: 105%;
  flex-grow: 1;
  text-align: left;
  padding: 15px;
  margin: 5px;
  border: 1px solid #999;
  border-radius: 10px;
  background-color: #fff;
  color: #666;
  min-height: 65px;
  z-index: 1;
  position: relative;
  transition: 0.2s all ease;
  font-size: 0.8em;
  h6 {
    font-size: 0.8rem;
  }

  i {
    position: absolute;
    z-index: 0;
    font-size: 40px;
    opacity: 0.4;
    top: 10px;
    left: 10px;
  }
  .alt {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .cardNotification {
    flex: 40% !important;
  }
}
@mixin cardON() {
  border-color: #000;
  opacity: 1;
  background-color: #fff;
  .coments-content {
  }
  .sendComnet {
    opacity: 1 !important;
  }
  .tbb {
    td,
    th,
    tbody {
    }
  }
}

.bordaUnread {
  border-color: red !important;
}

.cardNotification:hover {
  @include cardON();
}
.showVisible {
  opacity: 1 !important;
  cursor: default;
  @include cardON();
}

.bgGray {
  font-size: 10px;
  font-weight: bold;
}
.iOfLOG {
  i {
    position: relative;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
    top: 0;
    right: 0;
    left: 0;
  }
}
</style>